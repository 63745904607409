import { getCompaniesApi } from './api'

export const state = {
  companiesOptions: [],
  isCompaniesLoaded: false,
}

export const getters = {
  companiesOptions(state) {
    return state.companiesOptions.map((item) => ({
      ...item,
      key: item.id,
      text: item.displayName,
    }))
  },

  isCompaniesLoaded(state) {
    return state.isCompaniesLoaded
  },
}

export const mutations = {
  SET_COMPANIES(state, data) {
    state.companiesOptions = data.items
    state.isCompaniesLoaded = true
  },
}

export const actions = {
  fetch({ commit }) {
    getCompaniesApi().then((data) => {
      commit('SET_COMPANIES', data)
    })
  },

  destroy({ commit }) {
    commit('SET_COMPANIES', [])
  },
}
