import api from '@api'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@/src/data/qualification'
import { transformCompaniesForList } from '@/src/data/msp'

const getQualifications = (filters = {}) => {
  const qualDetails = []
  if ('companyIds' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.companyIds,
        'long',
        'db'
      )
    )
  }
  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  return qualDetails
}

export function getCompaniesApi(filters, limit, offset, params = {}) {
  const qualDetails = [...getQualifications(filters)]
  return api
    .post(
      '/company/search/byqual',
      {
        qualDetails: buildFlatQualificationStructure(qualDetails),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformCompaniesForList) || [],
        total: data.totalCount,
      }
    })
}
