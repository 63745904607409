import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('company')

export const CompanyComputed = {
  ...mapGetters({
    companiesOptions: 'companiesOptions',
    isCompaniesLoaded: 'isCompaniesLoaded',
  }),
}

export const CompanyMethods = {
  ...mapActions({
    fetchCompany: 'fetch',
  }),
}
